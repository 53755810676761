
.campaign {
	ul {
		margin-bottom: 30px;
	}
	video {
		width: 100%;
		margin-bottom: 30px;
	}
}
